<template>
    <div v-if="!row.item.is_canceled">
        {{ row.item.netto | priceFormat }} &euro;
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>